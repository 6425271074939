import * as React from "react";
import Spinner from "react-bootstrap/Spinner";
import "./loader.css";
// import lottieJson from '../../assets/animate/66503-card-encode.json';
import Logo from "../../logo.png";
export default function CircularIndeterminate() {
  return (
    <div className="loader">
      <Spinner animation="grow" variant="success" />
    </div>
  );
}
