import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import  Logo from './logoprfinal.jpg'

function Layout() {
  return (
    <Navbar
      collapseOnSelect
      expand='lg'
      style={{ backgroundColor: '#FFFFFF', borderBottom: '1px solid #e9e9e9' }}
    >
      <Container>
        <Navbar.Brand href='/'>
          <img src={Logo} alt='form' style={{ maxHeight: '217px' }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse className='justify-content-end'>
          <Nav>
            <Nav.Link href='#'>Inicio</Nav.Link>
            {/* <Nav.Link href='/'>Afíliate</Nav.Link>
            <Nav.Link href='/generate'>Generar enlace</Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Layout;
